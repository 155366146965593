import React from 'react'
import classNames from 'classnames'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { ArticleImage } from 'Components/Article/ArticleImage'
import Text from 'Components/texts/Text'
import Touchable from 'Components/touchables/Touchable'
import { getArticleLink } from 'Utils/common'
import { ANIM_DELAY, fadeUp } from 'Utils/animations'
import styles from './ArticleAbstract.module.scss'

export interface ArticleAbstractProps {
  className?: string
  article: ArticleFragment
  index: number
}

export const ArticleAbstract = (props: ArticleAbstractProps) => {
  const { className = '', article, index } = props

  const { title, main_tag: mainTag, slug } = article

  const startIndex = index + 2 // 2 headings

  return (
    <Touchable
      link={getArticleLink(slug)}
      className={classNames([styles.root, className])}
    >
      <ArticleImage
        article={article}
        className={styles.image}
        {...fadeUp({ delay: startIndex * ANIM_DELAY })}
      />
      <Text
        variant="md"
        className={styles.title}
        {...fadeUp({ delay: startIndex * ANIM_DELAY + 100 })}
      >
        {title}
      </Text>
      <Text
        variant="sm"
        className={styles.tag}
        {...fadeUp({ delay: startIndex * ANIM_DELAY + 200 })}
      >
        {mainTag}
      </Text>
    </Touchable>
  )
}
