import React from 'react'
import classNames from 'classnames'
import { ArticlesData, getSortedArticles } from 'Utils/common'
import Row from 'Components/Row/Row'
import { ArticleAbstract } from 'Components/Article/ArticleAbstract'
import styles from './ArticlesRow.module.scss'

export type ArticlesRowProps = {
  className?: string
  articlesData: ArticlesData
}

export const ArticlesRow = (props: ArticlesRowProps) => {
  const { className = '', articlesData } = props

  if (!articlesData) {
    return null
  }

  const articles = getSortedArticles(articlesData).slice(0, 3)

  return (
    <Row className={classNames([styles.root, className])} vertical="top">
      {articles.map((article, index) => (
        <ArticleAbstract
          key={article.id}
          index={index}
          article={article}
          className={styles.article}
        />
      ))}
    </Row>
  )
}
