import React from 'react'
import classNames from 'classnames'
import Text, { TextVariants } from 'Components/texts/Text'
import { fadeUp } from 'Utils/animations'
import styles from './Headings.module.scss'

export type HeadingsProps = {
  className?: string
  descriptionClassName?: string
  data: {
    heading?: string
    description?: string
  }
  titleVariant?: TextVariants
}

const Headings = (props: HeadingsProps) => {
  const {
    className = '',
    descriptionClassName = '',
    data,
    titleVariant = 'heading',
  } = props

  const { heading, description } = data

  return (
    <div className={classNames([styles.root, className])}>
      <Text
        className={styles.heading}
        Component="h2"
        variant={titleVariant}
        {...fadeUp()}
      >
        {heading || ''}
      </Text>
      {description && (
        <Text
          className={classNames([styles.description, descriptionClassName])}
          variant="sm"
          {...fadeUp({ index: 1 })}
        >
          {description}
        </Text>
      )}
    </div>
  )
}

export default Headings
